import React from 'react';
import { PageProps, useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/layout/Layout';
import MetaData from '../../components/seo/MetaData';
import OrganizationStructuredData from '../../components/seo/OrganizationStructuredData';
import Container from '../../components/layout/Container';
import SmallContainer from '../../components/layout/SmallContainer';
import Image from "../../images/hvor-mange-vegetarer-var-der-tidligere.jpg";
import Info from "../../images/hvor-mange-vegetarer-er-der-i-danmark.png";

interface Props extends PageProps {
  location: any;
}

const MangeVegetarer: React.FunctionComponent<Props> = ({ location }) => {

  const { site } = useStaticQuery<any>(
    graphql`
      query mangeQuery{
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  
  return (
    <Layout>
      <MetaData 
        title="Hvor mange vegetarer er der i Danmark?" 
        thumbnailUrl={site.siteMetadata.siteUrl + Info}
        description="Der kommer flere vegetarer i Danmark? Men hvor mange er der egentlig? Her får du information omkring antallet af vegetarer samt udviklingen de sidste år."
        pageUrl={location.pathname}
        imageAlt="Hvor mange vegetarer er der i Danmark"
      />
      <OrganizationStructuredData />
      <div className="bg-white">
        <Container className="pt-4 text-center">
          <h1>Hvor mange vegetarer er der i Danmark?</h1>
          <hr className="w-48 text-center mx-auto mb-2 border-2" />
        </Container>
        <SmallContainer className="pt-4 mb-16">
            <img 
              src={Info} 
              alt="Hvor mange vegetarer er der i Danmark"
              className="rounded mt-4 mb-4"
            />
            <p className="text-base">
              Det er ikke nogen hemmelighed, at der kommer flere vegetarer i Danmark. Men hvor mange vegetarer er der egentlig?
              Her i artiklen giver vi information omkring antallet af vegetarer og lignende diæter.
            </p>
            <h4>Størstedelen af Danmark spiser stadig kød</h4>
            <p className="text-base">
              Selvom vegetarisk og vegansk mad oplever stor opmærksomhed er det ikke en stor del af af befolkningen. 
              Faktisk er det i skrivende stund kun omkring 2.5% danskere, som lever vegetarisk. 
              Dette svarer til ca. 140.000 danskere. Tallene kommer fra en analyse, som Dansk Vegetarisk Forening og Coop har lavet i 2019.
              Derfor er tallene relativt nye.
            </p>
            <h4>Unge danskere udtrykker ønske om mere vegetarisk mad</h4>
            <p className="text-base">
              Den brede befolkning i Danmark er ikke udpræget vegetarisk. Der er dog stadig et voksende ønske om at spise mere vegetarisk mad.
              Undersøgelsen fra Dansk Vegetarisk Forening og Coop viste, at hele 74% af unge mellem 18 - 34 har et ønske om at spise mindre kød.
              Grunden blandt unge for vegetarisk kost er mest klimaet. Den næststørste grund er sundhed.
            </p>
            <p className="text-base">
              For den ældre befolkning er der forskel i grundlagt for ønsket om mere vegetarisk kost.
              Her er sundhed den mest udbredte årsag. Klima er den næstmest udbredte årsag og denne årsag haler kraftigt ind på sundhed.
              Der er derfor stor chance for, at næste undersøgelse vil vise, at klima er danskernes hovedårsag til at mindske deres kødindtag.
            </p>
            <h4>Hvor mange vegetarer var der tidligere?</h4>
            <img 
              src={Image} 
              alt="Hvor mange vegetarer var der tidligere i Danmark"
              className="rounded mt-4 mb-4"
            />
            <p className="text-base">
              De sidste tal før den nævnte undersøgelser er fra 2017. Så det er stadig relativt nye tal.
              Her var der ca. 1.8% vegetarer i Danmark. Dette svarer til 100.800 mennesker. 
              Der har altså været en tilgang af vegetarer på omkring 40.000 mennesker, hvilket må siges at være en hel del.
            </p>
            <p className="text-base">
              Før 2017 er der ikke mange underbyggede målinger af antallet af vegetarer.
              Selv denne undersøgelse i 2017 er kun baseret på ca. 1000 mennesker, som skal være repræsentative for befolkningen.
              Dette gør sig dog ikke altid gældende og man skal derfor indregne en usikkerhed i tallene.
              Man kan dog godt regne med, at de reelle tal ligger i omegnen af de nævnte.
            </p>
            <h4>Hvor mange vegetarer er der i andre lande?</h4>
            <p className="text-base">
              Det er altid spændende at sammenligne med andre lande.
              Specielt lande, som har en lignende kultur. 
              Her kan man danne sig et godt billede af ens status.
              Dette gælder også for vegetarer. 
            </p>
            <h6>Tyskland</h6>
            <p className="text-base">
              Den første vegetariske kongres fandt allerede sted i Tyskland i 1889. Det har altså været en del af kulturen meget længe.
              I 2016 fandt en undersøgelse ud af, at omkring 10% af tyskerne er vegetarer. Dette svarer til omkring 8 millioner mennesker, hvilket gør Tyskland til det europæiske land med flest vegetarer.
              Desuden er det også det vestlige land med den højeste andel af vegetarer.
            </p>
            <h6>Norge</h6>
            <p className="text-base">
              Norge har en lidt større andel af vegetarer sammenlignet med Danmark.
              Den sidste undersøgelse har vist, at omkring 4% af Norges befolkning er vegetarer.
              Dette svarer til ca. 200.000 mennesker.
            </p>
            <h6>Indien</h6>
            <p className="text-base">
              Der er generelt mange vegetarer i de asiatiske lande.
              Indien er dog helt unikt på dette punkt. En undersøgelse fra 2013 påpeger, at Indien faktisk har flere vegetarer end alle andre lande i verden kombineret.
              Landet har nemlig en høj procentdel af vegetarer samtidig med, at det er en af de mest folkerige nationer i verden.
              De fleste kilder nævner, at der er mere end 270 millioner vegetarer i landet.
              Det er dog ikke altid på grund af de samme årsager, som vi ser i Vesteuropa.
            </p>
            <h6>Kina</h6>
            <p className="text-base">
              Kina er generelt end meget anden historie end Indien. Her er slet ikke ligeså mange vegetarer.
              Det mest omtalte estimat ligger på omkring 4% - 5%. Nogle undersøgelser viser dog kun lavere end 1% af de adspurgte svarer, at de er vegetarer.
              Generelt er indtaget af kød voksende i Kina. Dette er typisk præget af en voksende befolkning og en voksende levestandard.
            </p>
            <h6>Afrika</h6>
            <p className="text-base">
              Der er meget få undersøgelser omkring vegetarer i Afrika. Den generelle tendens er dog, at der ikke er mange vegetarer.
              Dette er fordi de traditionelle retter typisk inkluderer kød. Nogle enkelte egne har vegetariske retter, men det er et fåtal.
            </p>
            <h6>USA</h6>
            <p className="text-base">
              USA er et meget stort land med mange forskellige mennesker. De 50 stater er meget forskellige, men samtidig ens på mange punkter.
              Langt størstedelen af landet spiser stadigvæk kød. Nogle stater er dog begyndt og have større andele af vegetarer og veganere.
              Den sidste undersøgelse fra 2018 fandt ud af, at ca. 5% - 8% af amerikanerne er vegetarer eller veganere.
              Dette betyder, at i omegnen af 12 - 20 millioner mennesker ikke spiser kød.
            </p>
        </SmallContainer>
      </div>
    </Layout>
  );
};

export default MangeVegetarer;